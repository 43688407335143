import styled from "styled-components"

const Figure = styled.figure`
  margin: 0;
  overflow: hidden;
  ${props =>
    props.flex
      ? `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `
      : ""}
  grid-column: ${props => (props.fullWidth ? "-1/1" : "2")} !important;
`

export default Figure
