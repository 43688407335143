import React from "react"
import FigCaption from "./FigCaption"
import Figure from "./Figure"

export default function YoutubeEmbed({
  videoId,
  caption,
  aspectRatio,
  ...rest
}) {
  return (
    <Figure {...rest}>
      <iframe
        style={{ width: "100%", height: `${(1 / aspectRatio) * 80}vh` }}
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <FigCaption>{caption}</FigCaption>
    </Figure>
  )
}
