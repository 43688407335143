import styled from "styled-components"

export const PanoramaWrapper = styled.div`
  height: 50vh;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  img {
    height: 100%;
    display: block;
    margin: auto;
  }
`
