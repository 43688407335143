import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import SEOHead from "../components/SEOHead"
import { getSrc } from "gatsby-plugin-image"
import BlogCard from "../components/BlogCard"
import Grid from "../components/Grid"
import { components, HeaderWrapper, TextLayout } from "./base"
import { formatDate } from "../utils"

export default function BlogPost({ data }) {
  const {
    title,
    excerpt,
    date,
    photos,
    content,
    hero,
    relatedPosts: relatedPostsForward,
    relatedPostsReverse,
  } = data.allGraphCmsBlogPost.nodes[0]

  const blogComponents = {
    ...components,
    IndexedImage: ({ index, ...rest }) => (
      <components.IndexedImage source={photos} index={index} {...rest} />
    ),
  }

  const relatedPosts = [...relatedPostsForward, ...relatedPostsReverse]

  const coreContent = (
    <MDXProvider components={blogComponents}>
      <MDXRenderer localImages={photos}>
        {content.markdownNode.childMdx.body}
      </MDXRenderer>
    </MDXProvider>
  )
  return (
    <Layout>
      <SEOHead
        title={`${title}`}
        description={excerpt}
        image={getSrc(hero.src.localFile)}
        article={true}
      />
      <Navbar />
      <TextLayout>
        <HeaderWrapper>
          <h1>{title}</h1>
          <h4>{excerpt}</h4>
          <h4>{formatDate(date)}</h4>
        </HeaderWrapper>
        {coreContent}
        {relatedPosts.length > 0 && (
          <Grid style={{ "--auto-grid-min-size": "1fr" }}>
            <h1>More like this...</h1>
            {relatedPosts.map(post => (
              <BlogCard
                compact
                {...post}
                slug={`/writings/${post.slug}`}
                key={post.slug}
                style={{ "--card-body-gap": "1rem" }}
              />
            ))}
          </Grid>
        )}
      </TextLayout>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    allGraphCmsBlogPost(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        excerpt
        date
        hero {
          src {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        photos {
          src {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          caption
          type
        }
        content {
          markdownNode {
            childMdx {
              body
            }
          }
        }
        relatedPosts {
          slug
          title
          date
          excerpt
          hero {
            src {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        relatedPostsReverse {
          slug
          title
          date
          excerpt
          hero {
            src {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
