import React from "react"
import { ReactCompareSlider } from "react-compare-slider"
import FigCaption from "./FigCaption"
import Figure from "./Figure"

export default function ImageCompare({
  imageOne,
  imageTwo,
  caption,
  fullWidth,
  panorama,
  ...rest
}) {
  return (
    <Figure fullWidth={fullWidth || panorama}>
      <ReactCompareSlider itemOne={imageOne} itemTwo={imageTwo} {...rest} />
      <FigCaption>{caption}</FigCaption>
    </Figure>
  )
}
