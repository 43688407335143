import React from "react"
import { getImage, getSrc, GatsbyImage } from "gatsby-plugin-image"

import { PanoramaWrapper } from "./style"
import Figure from "../Figure"
import FigCaption from "../FigCaption"

export default function Image({ src, type, caption, nocaption }) {
  const isPano = type === "PANORAMA"
  const fullWidth = type === "FULL_WIDTH" || isPano
  return (
    <Figure fullWidth={fullWidth} flex>
      {isPano ? (
        <PanoramaWrapper>
          <img src={getSrc(src.localFile)} alt={caption} />
        </PanoramaWrapper>
      ) : (
        <GatsbyImage image={getImage(src.localFile)} alt={caption} />
      )}
      {!nocaption && <FigCaption className="text_small">{caption}</FigCaption>}
    </Figure>
  )
}
