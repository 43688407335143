import React from "react"
import styled from "styled-components"
import ImageCompare from "../components/ImageCompare"
import YoutubeEmbed from "../components/YouTubeEmbed"

import Image from "../components/Image"
import { Link } from "gatsby"
import ExternalLink from "../components/ExternalLink"
import Figure from "../components/Figure"
import FigCaption from "../components/FigCaption"

function IndexedImage({ source, index, ...rest }) {
  return <Image {...source[index]} {...rest} />
}

export const components = {
  Image,
  YoutubeEmbed,
  ImageCompare,
  IndexedImage,
  Link,
  a: ExternalLink,
  figure: Figure,
  figcaption: FigCaption,
}

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  gap: 0.3rem;
  & * {
    margin: 0;
    padding: 0;
  }
`

export const TextLayout = styled.main`
  margin: 50px 0;
  display: grid;
  grid-template-columns: 1fr min(75ch, calc(100% - 32px)) 1fr;
  & > * {
    grid-column: 2;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1rem 0;
  }
`
